@tailwind base;
@tailwind components;
@tailwind utilities;


#scrollTopBtn {
  display: none; /* Inicialmente oculto */
  position: fixed; /* Fijo en la pantalla */
  bottom: 20px; /* Margen desde abajo */
  right: 20px; /* Margen desde la derecha */
  z-index: 99; /* Asegurarse de que esté por encima de otros elementos */
  font-size: 18px; /* Tamaño del texto */
  border: none; /* Sin borde */
  outline: none; /* Sin borde al hacer clic */
  background-color: #333; /* Color de fondo */
  color: white; /* Color del texto */
  cursor: pointer; /* Cursor en forma de mano */
  padding: 10px; /* Espacio interno */
  border-radius: 50%; /* Bordes redondeados */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombra */
}

#scrollTopBtn:hover {
  background-color: #555; /* Cambio de color al pasar el mouse */
}
